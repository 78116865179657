.login-container {
    max-width: 400px;
    margin:  auto;
    padding: 30px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 10px;
    margin-top: 12%;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2); /* Apply the box shadow */
  }
  
  h2 {
    margin: 0;
    text-align: center;
  }
  
  .form-group {
    margin-top: 15px;
    margin-bottom: 10px;
  }


  input{
    border-color: rgb(100, 99, 99) !important;
    outline-color: rgb(255, 255, 255);
  }
  
  h2.login-header {
    width: 100%;
    /* display: grid; */
    /* grid-template-columns: 100px 1fr; */
    text-align: inherit;
  }
  label.userLabel {
    display: block;
    margin-bottom: 5px;
    /* margin-left: -2%; */
  }
  
  input[type="text"], input[type="password"] {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin: 0 0px;
    outline: #cccccc;
}
  img.tsilogo {
    width: 45PX;
}
  
  .login-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background-color: #3f90c7;
    color: white;
    text-align: center;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  .login-btn:hover {
    transition: all .5s ease;
    background-color: #0a578b;
  }
  .login-header-div {
    display: grid;
    grid-template-columns: 55px 1fr;
}

.outerContainer {
  width: 100%;
  height: 100vh;

  background-color: rgb(158, 157, 157);

}

.error-div {
  margin: 0;
  background-color: #e6c4cc;
  width: 237px;
  height: 30px;
}
  h4.error-tag {
    width: 100%;
    text-align: center;
    margin: auto;
    color: red;
    font-size: 15px;
}