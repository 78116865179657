.centerContainer {
    width: 100%;
    height: 90vh;
    /* background-color: rgb(158, 157, 157); */
}

.navbar-profile ul.nav>li>a {
    color: #777;
    padding-top: 15px;
    padding-bottom: 15px;
    line-height: 20px;
}

.navbar-profile ul.nav>li .dropdown-menu {
    right: 0;
    left: auto;
}

.page-body {
    position: fixed;
    width: 100vw;
    height: 100vh;
}
.work-area {
    position: fixed;
    width: 96vw;
    left: 1vw;
    height: 98vh;
}
.pop-up-div {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;

}


@media (max-width: 767px) {
    .navbar-profile {
        position: absolute;
        display: inline-block;
        width: auto;
        right: 80px;
        top: 0;
        text-align: center;
        margin: auto;
    }
}