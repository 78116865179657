.navbar {
    display: flex;
    align-items: center;
    vertical-align: center;
    justify-content: space-between;
    padding: 10px;
    background-color: #1fc6f5;
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #000000;
}

.logo-image {
    width: 15%;
    margin-right: 10px;
}

.logo-image:hover {
    transform: scale(1.1); 
  }

.logo-text {
    font-size: 4vh;
    font-weight: 600;
    font-family: "Times New Roman", Times, serif;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.nav-menu {
    display: flex;
    margin: 0;
    padding: 0;
}

.nav-item {
    margin-left: 5vw;
}

.nav-link {
    text-decoration: none;
    color: #fff;
    transition: all 0.3s ease;
}

.username {
    font-family: Arial, sans-serif;
    /* Recommended font family */
    display: inline-block;
    font-size: 15px;
    font-weight: 200;
    padding: 5px 10px 5px 10px;
    color: #ffffff;
    background-color: #000000;
    border-radius: 4px;
    transition: color 0.3s ease;
    cursor: default;
}

.username:hover {
    transition: all .5s ease;
    color: #000000;
    background-color: #ffffff;
}

.logoff-image {
    width: 30px;
    margin-right: 10px;
    color: #000000;
}

.logoff-image:hover {
    transform: scale(1.1); 
    cursor: pointer;
}

/* Media Query for Responsive Design */
@media (max-width: 768px) {
    .navbar {
        flex-direction: column;
    }

    .nav-menu {
        margin-top: 10px;
    }

    .nav-item {
        margin: 5px 0;
        cursor: pointer;
    }
}