header{
    font-family: Arial, sans-serif;
    /* Recommended font family */
    display: inline-block;
    font-size: 15px;
    font-weight: 200;
    padding: 5px 10px 5px 10px;
    color: #ffffff;
    background-color: #000000;
    border-radius: 4px;
    transition: color 0.3s ease;
    cursor: default;
}