.btn-div {
    /* display: flex; */
    width: 98vw;
    margin-top: 20px;
}
button.upload-home-btn {
    border: none;
    outline: none;
    background-color: black;
    color: white;
    width: 85px;
    height: 35px;
    border-radius: 4px;
    margin: 5px;
    /* padding-bottom: 10px; */
    /* display: flex; */
    /* align-items: center;
    justify-content: center; */
    margin-left: 10px;
    font-weight: 400;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2); /* Apply the box shadow */

    
}
button.upload-home-btn:hover {
    transition: all .5s ease;
    background-color: #a8a8ac;
    color: rgb(10, 10, 10);
}
.form-area-upload {
    max-width: 450px;
    margin: 0 auto;
    padding: 30px;
    background-color: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 85px;
    background-color: #ffffff;
    padding: 20px;
    border-radius: 4px;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2); /* Apply the box shadow */
}
img.close-upload {
    width: 20px;  
}
.header-div {
    display: flex;
    justify-content: space-between;
}
img.close-upload {
    width: 20px;
    margin-top: -2px;
    margin-left: 1px;
}
button.close-upload-btn {
    width: 35px;
    border: none;
    outline: none;
    border-radius: 4px;
    background-color: #e6e6e800;
    /* padding: 0px; */
    margin-top: -10px;
}
input.form-control-upload {
    width: 320px;
    padding: 8px;
    border: 1px solid #f8f8f8;
    border-radius: 4px;
    margin: 0 35px;
    outline: #e5e3e3;
}
.dropDown-div {
    position: relative;
    width: 78%;
    height: 15%;
    margin-left: 9%;

}
input.comment-text {
    width: 320px;
    margin: 0px 35px;
    height: 40px;
}
textarea.comment-text {
  width: 320px;
  margin: 0px 35px;
  /* min-height: 40px; */
}
h4.mandateTag {
    position: relative;
    color: red;
    background-color: #e6a9a96e;
    width: 100%;
    text-align: center;
    height: 25px;
    font-size: 20px;
}

.Alert-container-upload {
    position: fixed;
    width: 305px;
    height: 125px;
    background-color: #ececef;
    left: 530px;
    /* top: 400px; */
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 2px;
    box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2); /* Apply the box shadow */
}
.Alert-container-upload-file {
    position: relative;
    width: 305px;
    height: 125px;
    background-color: #ececef;
    justify-content: center;
    left: 30px;
    /* top: 400px; */
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 10px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
span.LoaderFade {
    margin-top: px;
    width: 50px !important;
    /* position: relative; */
    height: 50px !important;
    border-color: rgb(63 144 199) rgb(63 144 199) transparent !important;
    margin-left: 170px !important;
    margin-top: 150px;
}