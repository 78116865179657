.popup-container {
    position: fixed;
    width: 30vw;
    height: 20vh;
    background-color: #ececef;
    left: 35vw;
}
.popup-container {
    position: fixed;
    width: 30vw;
    height: 20vh;
    background-color: #ececef;
    left: 35vw;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-radius: 2px;
    box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
}
h4.timeout-tag {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 25%;
    font-size: 20px;
}
button.close-session-btn {
    position: absolute;
    top: 50%;
    left: 45%;
    background-color: dodgerblue;
    color: white;
    border: none;
    font-weight: 700;
    outline: none;
    border-radius: 4px;
}