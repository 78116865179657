.table-container {
  padding: 8px;
  /* width: '100%'; */
  border-radius: 4px;
  box-shadow: 1px 1px 4px 4px rgba(0, 0, 0, 0.2);
  /* Apply the box shadow */
}

.datagrid {
  width: 100%;
}

.test-ai {
  width: 10px;
  color: #000000;
}

.css-levciy-MuiTablePagination-displayedRows {
  padding-top: 15px !important;
}
.css-pdct74-MuiTablePagination-selectLabel {
  padding-top: 15px !important;
}

.css-1lymaxv-MuiDataGrid-root .MuiDataGrid-cellContent {
  font-size: 12px;
}

.css-t89xny-MuiDataGrid-columnHeaderTitle{

  font-size: 13px;
  font-weight: bold;

}
::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
  background-color: rgba(221, 218, 218, 0.952)ff;
  -webkit-transition: width 5s;
  transition: 1s;
  right: 0%;
}


::-webkit-scrollbar-thumb {
  background-color: #6e6e6e69;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #3f3f3fb4;
}

